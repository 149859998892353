import { useState } from "react";
import { Row, Col, Tabs, Form, Select, InputNumber, DatePicker, Button, Collapse } from "antd";
import { Link } from "gatsby";
import { handlers } from "../../../helpers/handlers";
import locale from "antd/es/date-picker/locale/es_ES";
import useFormTabs from "../../hooks/home/useFormTabs";
import CallCenterModal from "./CallCenterModal";

const { TabPane } = Tabs;
const { Option } = Select;
const { Panel } = Collapse;

const FormTabs = ({ t, settings }) => {
  const {
    loading,
    onFinish,
    branchOffices,
    selectedDate,
    setSelectedDate,
    reservationQuery,
    setReservationQuery,
    handleCalendarAvailability,
  } = useFormTabs();
  const [form] = Form.useForm();
  const [currentQty, setCurrentQty] = useState(1);
  const [showMaxPatientsMessage, setShowMaxPatientsMessage] = useState(false);
  const [showCallCenterModal, setShowCallCenterModal] = useState(false);
  const countryCode = process.env.GATSBY_COUNTRY_VALUE;

  const handleOnChangeBranchOffice = (value) => {
    if (value) {
      form.setFieldsValue({
        patientsQty: 1,
        date: null,
      });
      setCurrentQty(1);
      setReservationQuery({ branchOffice: value, patientsQty: 1 });
    }
  };

  const handleOnChangePatientsQty = (value) => {
    form.setFieldsValue({
      patientsQty: value,
    });
    setReservationQuery({ ...reservationQuery, patientsQty: value });
  };

  const handleOnChangeDatePicker = (value) => {
    if (value) {
      setSelectedDate(value);
    }
  };

  const phoneAid = () => {
    return (
      <Col lg={24} xs={24}>
        <p className="bold mt-30 mb-0">¿Necesitas ayuda? {settings?.nodes[0]?.fields?.centralPhone && 
          <a href={`tel:${settings.nodes[0].fields.centralPhone}`}>
            {handlers.splitPhone(settings.nodes[0].fields.centralPhone)}
          </a>
        }
        </p>
      </Col>
    );
  };

  const subtractPatient = () => {
    let value;
    if (currentQty > 1) {
      value = currentQty - 1;
      setCurrentQty(value);
      handleOnChangePatientsQty(value);
      setShowMaxPatientsMessage(false);
    }
  };

  const addPatient = () => {
    let value;
    const maxQtyByCountry = countryCode === 'CL' ? 10 : 5;

    if (currentQty < maxQtyByCountry) {
      value = currentQty + 1;
      setCurrentQty(value);
      handleOnChangePatientsQty(value);
      return;
    }

    if (currentQty === maxQtyByCountry) {
      setShowMaxPatientsMessage(true);
    } 
  };

  const handleShowCallCenterModal = () => {
    setShowCallCenterModal(!showCallCenterModal);
  };


  const itemsContent = [
    {
      key: '1',
      label: countryCode === 'CL' ?
        <h3 className='mb-0' dangerouslySetInnerHTML={{ __html: t('home.bannerForm.mainTitle')}} /> : <h2 className='tab-title-PE mb-0' dangerouslySetInnerHTML={{ __html: t('home.bannerForm.mainTitle')}} />,
      content: (
        <Row className="pt-lg-30 pb-lg-30 pl-lg-40 pr-lg-40">
          <Col lg={24} xs={24}>
          {
            countryCode === 'CL' ?
              <h4 dangerouslySetInnerHTML={{ __html: t('home.bannerForm.title')}} />
            :
              <p className="content-title-PE mb-20" dangerouslySetInnerHTML={{ __html: t('home.bannerForm.title')}} />
          }
            <p className={countryCode === 'PE' && 'mb-20'} dangerouslySetInnerHTML={{ __html: t('home.bannerForm.subtitle') }} />
            <Row>
              <Col lg={24} xs={24}>
                <Form
                  form={form}
                  size="large"
                  layout="horizontal"
                  onFinish={onFinish}
                  className="cmt-form"
                >
                  <Row justify='center' gutter={[15, 15]}>
                    <Col lg={6} xs={24}>
                      <Form.Item
                        name='branchOffice'
                        rules={[{
                          required: true,
                          message: `${t('home.bannerForm.branchOffice')} requerida`,
                        }]}
                      >
                        <Select
                          placeholder={`Selecciona ${t('home.bannerForm.branchOffice')}`}
                          className='cmt-select'
                          loading={loading}
                          onChange={handleOnChangeBranchOffice}
                          aria-label={`Selecciona ${t('home.bannerForm.branchOffice').toLowerCase()}`}
                        >
                        {
                          (branchOffices.length > 0) && branchOffices.map((branchOffice, index) => {
                            return (
                              <Option
                                key={index}
                                value={branchOffice.id}
                              >
                                {branchOffice.name}
                              </Option>
                            )
                          })
                        }
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={8} xs={24}>
                      <Row align="middle">
                        <Col xxl={18} lg={16} md={20} sm={19} xs={16}>
                          <p className="quantity mb-0">Cantidad de {t('home.bannerForm.patients')}</p>
                          <Form.Item
                            name='patientsQty'
                            rules={[{
                              required: true,
                              message: 'Cantidad requerida',
                            }]}
                            hidden
                          >
                            <InputNumber
                              min={1}
                              max={countryCode === 'CL' ? 10 : 5}
                              bordered={false}
                              controls
                              keyboard={false}
                              readOnly
                              onChange={(value) => handleOnChangePatientsQty(value)}
                            />
                          </Form.Item>
                        </Col>
                        <Col xxl={6} lg={8} md={4} sm={5} xs={8}>
                          <Row>
                            <Col lg={8} xs={8}>
                              <Button
                                onClick={subtractPatient}
                                className='cmt-button disabled outline action'
                              >
                                -
                              </Button>
                            </Col>
                            <Col lg={8} xs={8}>
                              <Button
                                className='cmt-button disabled outline only-read'
                                style={{ cursor: 'text' }}
                              >
                                {currentQty}
                              </Button>
                            </Col>
                            <Col lg={8} xs={8}>
                              <Button
                                onClick={addPatient}
                                className='cmt-button disabled outline action'
                              >
                                +
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} xs={24}>
                      <Form.Item
                        name='date'
                        rules={[{
                          required: true,
                          message: 'Fecha requerida',
                        }]}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          locale={locale}
                          format={'DD/MM/YYYY'}
                          clearIcon={false}
                          className="cmt-date-picker"
                          placeholder="Seleccionar fecha"
                          inputReadOnly
                          value={selectedDate}
                          onPanelChange={handleOnChangeDatePicker}
                          disabledDate={handleCalendarAvailability}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={4} xs={24}>
                      <Button
                        className='cmt-button secondary bold'
                        type='primary'
                        block
                        htmlType='submit'
                      >
                        Buscar
                      </Button>
                    </Col>
                    {
                      showMaxPatientsMessage &&
                        <>
                          <Col lg={24}>
                            <p className='warning-text mb-0'>
                              El <strong>límite máximo</strong> por bloque horario es de <strong>{t('home.bannerForm.maxPatientsQty1')}</strong>. {t('home.bannerForm.maxPatientsQty2')}, <strong className='clickeable' onClick={handleShowCallCenterModal} aria-label="Haz click aquí">haz click aquí</strong>.
                            </p>
                          </Col>
                          <CallCenterModal
                            t={t}
                            showCallCenterModal={showCallCenterModal}
                            handleShowCallCenterModal={handleShowCallCenterModal}
                          />
                        </>
                    }
                  </Row>
                </Form>
              </Col>
              {phoneAid()}
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      key: '2',
      label: <h3 className='mb-0' dangerouslySetInnerHTML={{ __html: t('home.bannerForm.secondTitle')}} />,
      content: (
        <Row className="pt-lg-30 pb-lg-30 pl-lg-40 pr-lg-200">
          <Col lg={24} xs={24}>
            <h4 dangerouslySetInnerHTML={{ __html: t('home.bannerForm.secondSubTitle')}} />
            <p dangerouslySetInnerHTML={{ __html: t('home.bannerForm.secondContent')}} />
          </Col>
          <Col xl={4} lg={5} xs={24}>
            <Link to={t('home.bannerForm.secondUrlPath')}>
              <Button className='cmt-button secondary bold' type='primary' size='large'>
                Contáctanos
              </Button>
            </Link>
          </Col>
          {phoneAid()}
        </Row>
      ),
    },
    {
      key: '3',
      label: <h3 className='mb-0' dangerouslySetInnerHTML={{ __html: t('home.bannerForm.thirdTitle')}} />,
      content: (
        <Row className="pt-lg-30 pb-lg-30 pl-lg-40 pr-lg-200">
          <Col lg={24} xs={24}>
            <h4 dangerouslySetInnerHTML={{ __html: t('home.bannerForm.thirdSubTitle')}} />
            <p dangerouslySetInnerHTML={{ __html: t('home.bannerForm.thirdContent')}} />
          </Col>
          <Col xl={4} lg={5} xs={24}>
            <Link to={t('home.bannerForm.thirdUrlPath')}>
              <Button className='cmt-button secondary bold' type='primary' size='large'>
                Contáctanos
              </Button>
            </Link>
          </Col>
          {phoneAid()}
        </Row>
      )
    },
    {
      key: '4',
      label: <h3 className='mb-0' dangerouslySetInnerHTML={{ __html: t('home.bannerForm.fourthTitle')}} />,
      content: (
        <Row className="pt-lg-30 pb-lg-30 pl-lg-40 pr-lg-200">
          <Col lg={24} xs={24}>
            <h4 dangerouslySetInnerHTML={{ __html: t('home.bannerForm.fourthSubTitle')}} />
            <p dangerouslySetInnerHTML={{ __html: t('home.bannerForm.fourthContent')}} />
          </Col>
          <Col xl={4} lg={5} xs={24}>
            <Link to={t('home.bannerForm.fourthUrlPath')}>
              <Button className='cmt-button secondary bold' type='primary' size='large'>
                Contáctanos
              </Button>
            </Link>
          </Col>
          {phoneAid()}
        </Row>
      )
    },
  ];


  return (
    <Row justify='center' className='banner-form-wrapper'>
      <Col lg={24} xs={24}>
        <Row justify='center'>
          <Col lg={22} xs={22} className='collapse-wrapper'>
            <Collapse
              accordion
              defaultActiveKey='1'
              expandIconPosition='end'
            >
            {
              itemsContent?.map((elem, index) => {
                if (countryCode === 'PE') {
                  return (
                    <Panel header={elem.label} key={elem.key}>
                      {elem.content}
                    </Panel>
                  )
                } else if (countryCode === 'CL' && index !== 3) {
                  return (
                    <Panel header={elem.label} key={elem.key}>
                      {elem.content}
                    </Panel>
                  )
                }

                return null
              })
            }
            </Collapse>
          </Col>
          <Col lg={19} xs={22} className='tabs-wrapper'>
            <Tabs
              type='card'
              defaultActiveKey='1'
            >
            {
              itemsContent?.map((elem, index) => {
                if (countryCode === 'PE') {
                  return (
                    <TabPane tab={elem.label} key={elem.key}>
                      {elem.content}
                    </TabPane>
                  )
                } else if (countryCode === 'CL' && index !== 3) {
                  return (
                    <TabPane tab={elem.label} key={elem.key}>
                      {elem.content}
                    </TabPane>
                  )
                }

                return null
              })
            }
            </Tabs>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FormTabs;