import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { Row, Col, Button, Layout } from "antd";
import PublicLayout from "../components/PublicLayout";
import SEO from "../components/seo";
import imageUrlReplace from "./components/blog/imageUrlReplace";
import { useTranslation } from "react-i18next";
import HubspotForm from "./components/hubspotForm/hubspotForm";

const { Content } = Layout;

const OtherBatteries = ({ pageContext }) => {
  const {
    type,
    otherBattery: {
      id,
      fields: {
        title,
        subtitle,
        batteriesList,
        image,
      },
      meta_tag: {
        metaTitle,
        metaDescription,
      },
    },
  } = pageContext;
  const country = process.env.GATSBY_COUNTRY_VALUE;
  const { t } = useTranslation();

  const schemaExtraData = [{
    "@type": "Organization",
    logo: "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
    sameAs: [
      "https://www.facebook.com/CentroMedicoDelTrabajador",
      "https://www.linkedin.com/company/centro-medico-del-trabajador",
    ],
  }];

  const scrollToElement = () => {
    const element = document.getElementById('form');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src='//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "44540453",
          formId: country === 'CL' ? "8bee14bc-f59e-4e46-a77b-e57ee4f061fc" : "8d114b5b-41eb-4f97-9e94-3e2292617196",
          target: '#hubspotForm',
        })
      }
    });
  }, []);


  return (
    <PublicLayout>
      <SEO
        title={type === 'form' ? metaTitle : t('seo.contact.titleSuccessOtherBatteries')}
        description={type === 'form' ? metaDescription : t('seo.contact.descriptionSuccessOtherBatteries')}
        dataLayer={{
          userId: "",
          pageId: id && id,
          pageType: "project",
          category: "",
        }}
        schemaExtraData={schemaExtraData}
      />
      <Layout className="other-batteries-container">
        <Content>
          <Row justify='center'>
            <Col lg={24} xs={24} className="image-title-wrapper">
              <Row justify="center">
                <Col lg={24} xs={24}>
                  <div
                    style={{
                      backgroundImage: `url('${imageUrlReplace(image.sourceUrl)}')`,
                    }}
                    className={`image ${country === 'PE' && 'pe-style'}`}
                  >
                    <Row className="text-button-wrapper">
                      <Col lg={12} xs={22}>
                        <Row justify="end">
                          <Col xl={19} lg={21} xs={24} className="card-container">
                            <Row>
                              <Col lg={24} xs={24}>
                                <h1 className="title">{title}</h1>
                              </Col>
                              <Col lg={24} xs={24}>
                                <h2 dangerouslySetInnerHTML={{ __html: subtitle }} />
                              </Col>
                              <Col className="mt-5">
                                <Button
                                  className="cmt-button secondary"
                                  type="primary"
                                  size={country === 'CL' && "large"}
                                  onClick={scrollToElement}
                                >
                                  Contáctate con nosotros
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xl={19} lg={21} xs={20} className="list-wrapper mt-lg-80 mt-md-150 mt-sm-150 mt-xs-150">
              <Row>
                <h3>{t('otherBatteries.availableBatteriesList')}:</h3>
                <Col
                  lg={24} xs={24}
                  dangerouslySetInnerHTML={{ __html: batteriesList }}
                />
              </Row>
            </Col>
            <Col
              id="form"
              xl={19} lg={21} xs={24}
              className="form-wrapper pt-lg-100 pt-md-60 pt-sm-60 pt-xs-60 mb-60"
            >
              <Row justify="center">
                <Col lg={24} xs={24}>
                  <h4 className="text-center mb-30">{t('otherBatteries.contactUs')}</h4>
                  <HubspotForm type={type} country={country} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    </PublicLayout>
  );
};

export default OtherBatteries;

export const postQuery = graphql`
  query GET_OTHER_BATTERIES($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`