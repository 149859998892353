import React from "react";
import { Link } from "gatsby";
import { Row, Col, Button } from "antd";

const Pagination = ({ pageNumber, hasNextPage }) => {
  let prevLink = null;
  let nextLink = null;

  if (pageNumber === 1) {
    prevLink = `/blog/`;
  } else if (pageNumber > 1) {
    prevLink = `/blog/page/${pageNumber - 1}/`;
  }

  if (hasNextPage) {
    nextLink = `/blog/page/${pageNumber + 1}/`;
  }

  return (
    <Row justify="end" className="mt-70" gutter={[15, 15]}>
		{
			prevLink &&
				<Col xl={4} lg={6} xs={24}>
					<Link to={prevLink} replace>
						<Button
							className="cmt-button primary outline"
							type="primary"
							size="large"
						>
							Anterior
						</Button>
					</Link>
				</Col>
		}
		{
			nextLink &&
				<Col xl={4} lg={6} xs={24}>
					<Link to={nextLink} replace>
						<Button
							className="cmt-button primary outline"
							type="primary"
							size="large"
						>
							Siguiente
						</Button>
					</Link>
				</Col>
		}
    </Row>
  );
};


export default Pagination;