import React from "react";
import { Link, graphql } from "gatsby";
import PublicLayout from "../components/PublicLayout";
import { Row, Col, Layout, Button } from "antd";
import SEO from "../components/seo";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import equipoProfesionalCMT from '../images/equipoProfesionalCMT.webp';
import OurStatistics from "./components/home/OurStatistics";

const { Content } = Layout;

const AboutUs = ({ pageContext }) => {
  const {
    country,
  } = pageContext;
  const { t } = useTranslation();

  const schemaExtraData = [{
    "@type": "Organization",
    logo: "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
    sameAs: [
      "https://www.facebook.com/CentroMedicoDelTrabajador",
      "https://www.linkedin.com/company/centro-medico-del-trabajador",
    ],
  }];

  return (
    <PublicLayout>
      <SEO
        title={t('seo.aboutUs.title')}
        description={t('seo.aboutUs.description')}
        dataLayer={{
          userId: "",
          pageId: "313",
          pageType: "page",
          category: "",
        }}
        schemaExtraData={schemaExtraData}
      />
      <Layout className='about-us-page-container'>
        <Content>
          <Row justify='center'>
            <Col lg={24} xs={24}>
              <Row className='title-image-wrapper' align='stretch'>
                <Col lg={12} xs={24} className='title-wrapper'>
                  <Row justify='center' gutter={[0, 15]} align='middle'>
                    <Col xxl={14} lg={16} md={15} sm={17} xs={19}>
                      <h1 dangerouslySetInnerHTML={{ __html: t('aboutUs.mainTitle')}} />
                    </Col>
                    <Col xxl={14} lg={16} md={15} sm={17} xs={19}>
                      <Row>
                        <Col>
                          <Link to={country === 'CL' ? '/app/reservar/' : '/contacto/'}>
                            <Button className='cmt-button secondary' type='primary' size={country === 'CL' && 'large'}>
                              {country === 'CL' ? 'Agenda aquí' : 'Contáctanos'}
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} xs={24} className='image-container'>
                  <div
                    className='image-content'
                    style={{ backgroundImage: `url(${equipoProfesionalCMT})` }}
                    title="Trabajadores CMT Salud"
                  />
                </Col>
              </Row>
              <Row justify='center' className='content-text-wrapper pt-60'>
                <Col xxl={14} xl={country === 'CL' ? 15 : 19} lg={20} md={15} sm={17} xs={20}>
                  <h2>{t('aboutUs.subTitle')}</h2>
                  <h3 dangerouslySetInnerHTML={{ __html: t('aboutUs.description')}} />
                </Col>
              </Row>
              <Row
                justify='center'
                className="mission-wrapper pt-lg-60 pt-md-40 pt-sm-40 pt-xs-40 pb-lg-30 pb-md-30 pb-sm-30 pb-xs-30"
              >
                <Col xxl={14} xl={country === 'CL' ? 15 : 19} lg={20} md={15} sm={17} xs={20}>
                  <Row justify="space-between" gutter={[country === 'CL' ? 0 : 54, 20]}>
                    <Col lg={country === 'CL' ? 11 : 12} xs={24}>
                      <Row className={country === 'PE' && "card-item"}>
                        <Col lg={24} xs={24}>
                          <h4>{t('aboutUs.missionTitle')}</h4>
                          <p>{t('aboutUs.missionContent')}</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={country === 'CL' ? 11 : 12} xs={24}>
                      <Row className={country === 'PE' && "card-item"}>
                        <Col lg={24} xs={24}>
                          <h4>{t('aboutUs.visionTitle')}</h4>
                          <p>{t('aboutUs.visionContent')}</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <OurStatistics
                t={t}
                usedFrom='aboutUs'
                countryCode={country}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
    </PublicLayout>
  );
};

export default AboutUs;

export const postQuery = graphql`
  query GET_ABOUT_US($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
