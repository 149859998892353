import React from "react";
import { Link } from "gatsby";
import { Row, Col, Button, } from "antd";

const HubspotForm = ({ type, country }) => {
  return (
    <Row justify="center" className='contact-hubspot-container pt-60'>
    {
      type === 'form' ?
        <Col lg={24} xs={24}>
          <Row justify="center">
            <Col lg={24} xs={24}>
              <h3 className="text-center">Envíanos un <italic>mensaje</italic></h3>
              <p className="text-center">Estamos disponibles para resolver cualquier duda que tengas. <br/> Completa el formulario y un ejecutivo se pondrá en contacto contigo en menos de 24 horas (hábiles).</p>
            </Col>
            <Col lg={20} xs={24}>
              <div id="hubspotForm" />
            </Col>
          </Row>
        </Col>
      :
        <Col lg={24} xs={24}>
          <Row justify="center" gutter={[0, 30]}>
            <Col lg={24} xs={24}>
              <h3 className="text-center">¡Gracias por contactarnos!</h3>
              <p className="text-center">Un ejecutivo se pondrá en contacto contigo en menos de 24 horas (hábiles).</p>
            </Col>
            <Col>
              <Link to='/'>
                <Button
                  className='cmt-button primary'
                  type='primary'
                  size={country === 'CL' && "large"}
                >
                  Volver al Home
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
    }
    </Row>
  );
};

export default HubspotForm;