import React, { useEffect } from "react";
import PublicLayout from "../components/PublicLayout";
import {
  Row,
  Col,
  Layout,
  Button,
} from "antd";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import { useTranslation } from "react-i18next";
import ContactCards from "./components/contact/contactCards";
import HubspotForm from "./components/hubspotForm/hubspotForm";

const { Content } = Layout;

const Contact = (props) => {
  const {
    data: {
      wpgraphql: {
        settings,
      }
    },
    pageContext: { type, country },
  } = props;
  const { t } = useTranslation();

  const schemaExtraData = [{
    "@type": "Organization",
    "logo": "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
    "sameAs": [
      "https://www.facebook.com/CentroMedicoDelTrabajador",
      "https://www.linkedin.com/company/centro-medico-del-trabajador"
    ],
  }];

  useEffect(() => {
    const script = document.createElement('script');
    script.src='//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "44540453",
          formId: country === 'CL' ? "8bee14bc-f59e-4e46-a77b-e57ee4f061fc" : "8d114b5b-41eb-4f97-9e94-3e2292617196",
          target: '#hubspotForm',
        })
      }
    });
  }, []);

  
  return (
    <PublicLayout>
      <SEO
        title={type === 'form' ? t('seo.contact.titleForm') : t('seo.contact.titleSuccess')}
        description={type === 'form' ? t('seo.contact.description') : t('seo.contact.descriptionSuccess')}
        dataLayer={{
          userId: "",
          pageId: "157",
          pageType: "page",
          category: "",
        }}
        schemaExtraData={schemaExtraData}
      />
      <Layout className='contact-container'>
        <Content>
          <Row justify="center">
            <Col xxl={18} xl={country === 'CL' ? 21 : 19} lg={22} xs={24} className="mt-lg-80 mt-md-60 mt-sm-60 mt-xs-60">
              <Row justify="center">
                <Col lg={24} xs={22}>
                  <h1 className="text-center">{t("contact.title")}</h1>
                  <h2 className="text-center">{t("contact.subTitle")}</h2>
                </Col>
                <Col lg={24} xs={24} className="form-wrapper mt-40">
                  <HubspotForm type={type} country={country} />
                </Col>
                <Col lg={24} xs={20}>
                {
                  settings?.nodes?.length > 0 &&
                    <ContactCards settings={settings} t={t} />
                }
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    </PublicLayout>
  );
};

export default Contact;

export const contactSettings = graphql`
  query GET_CONTACT_SETTINGS($country: String, $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    wpgraphql {
      settings(where: { country: $country }) {
        nodes {
          fields {
            centralPhone
            centralEmail
            noticeEmail
          }
        }
      }
    }
  }
`;