import React, { useState, useEffect } from 'react'
import { Button, Col, Form, Input, Popover, Radio, Row, Space } from 'antd';
import { useTranslation } from "react-i18next"
import { DocumentCard, PhoneInput } from 'cmt-ui-kit';
import { format } from 'rut.js';
import evaluationTypes from '../../../../../utils/evaluationType.json';
import ModalExams from './modalExams';

const PatientForm = ({
  patient,
  countryCode,
  branchOffice,
  setCart,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [documentType, setDocumentType] = useState();
  const [form] = Form.useForm();
  const { t } = useTranslation();


  useEffect(() => {
    if (!documentType) {
      if (countryCode === 'PE') {
        form.setFieldsValue({ documentType: 'dni' });
        setDocumentType('dni');
      } else if (countryCode === 'CL') {
        form.setFieldsValue({ documentType: 'rut' });
        setDocumentType('rut');
      }
    }

    if (patient.documentNumber && patient.documentType) {
      form.setFieldsValue({ documentType: patient.documentType });
      form.setFieldsValue({
        documentNumber: patient.documentType === 'rut'
          ? format(patient.documentNumber)
          : patient.documentNumber
      });
    }

    if (patient.phone) {
      form.setFieldsValue({ phone: patient.phone });
    } else {
      if (countryCode && form) {
        form.setFieldsValue({ 'phone': PhoneInput.formatPhone(null, countryCode) });
      }
    }

    if (patient.profession) {
      form.setFieldsValue({
        profession: patient.profession,
      });
    }

    if (countryCode === 'PE') {
      form.setFieldsValue({
        names: patient.names,
        last_name: patient.last_name,
        email: patient.email,
        evaluation_type: patient.evaluation_type,
      });
    }

    form.setFieldsValue({
      external_id: patient?.external_id,
    })

  }, []);


  const onUpdateAttribute = (type, value) => {
    setCart(state => {
      const patients = [...state.patients];
      if (type === 'documentNumber') {
        patients[patient.number].documentNumber = documentType === 'rut' ? format(value, { dots: false }) : value;
        patients[patient.number].documentType = documentType;
      } else if (type === 'phone') {
        patients[patient.number].phone = `+${value}`;
      } else if (type === 'names') {
        patients[patient.number].names = value;
      } else if (type === 'last_name') {
        patients[patient.number].last_name = value;
      } else {
        patients[patient.number][type] = value;
      }
      return {
        ...state,
        patients,
      }
    });
  };


  return (
    <Form size="middle" form={form}>
      <Row justify="space-between" gutter={[0, 10]}>
        <Col lg={7} xs={24}>
          <p className="label-text mb-10">Requerido</p>
          <Button
            className='cmt-button secondary'
            type='primary'
            onClick={() => setModalVisible(true)}
            block
          >
            {t('scheduleTime.patientForm.addServicesButton')}
            <span className='icon-system-plus ml-15' />
          </Button>
        </Col>
        <Col lg={7} xs={24}>
          <p className="label-text mb-10">
            {t('scheduleTime.patientForm.typeAndDocumentNumberText')}
          </p>
          <DocumentCard
            countryCode={countryCode}
            setDocumentType={setDocumentType}
            form={form}
            handleOnDocumentNumberBlur={e => onUpdateAttribute('documentNumber', e.target.value)}
            type='person'
          />
        </Col>
        <Col lg={7} xs={24}>
          <p className="label-text mb-10">
            {t('scheduleTime.patientForm.phoneText')}
          </p>
          <PhoneInput.ReactPhone
            form={form}
            optional={countryCode !== 'PE'}
            countryCode={countryCode}
            onChangePhone={value => onUpdateAttribute('phone', value)}
          />
        </Col>
      </Row>

      <Row justify="space-between" gutter={[0, 10]} className='mb-10'>
        {
          countryCode === 'PE' &&
          <Col lg={7} xs={24}>
            <p className="label-text mb-10">
              {t('scheduleTime.patientForm.namesText')}
            </p>
            <Form.Item
              name="names"
              rules={[{
                required: true,
                message: "Nombres requeridos"
              }]}
            >
              <Input
                id='names'
                className="cmt-input"
                placeholder="Ingrese nombres"
                onChange={e => onUpdateAttribute('names', e.target.value)}
              />
            </Form.Item>
          </Col>
        }
        {
          countryCode === 'PE' &&
          <Col lg={7} xs={24}>
            <p className="label-text mb-10">
              {t('scheduleTime.patientForm.lastNameText')}
            </p>
            <Form.Item
              name="last_name"
              rules={[{
                required: true,
                message: "Apellidos requeridos",
              }]}
            >
              <Input
                id='last_name'
                className="cmt-input"
                placeholder="Ingrese apellidos"
                onChange={e => onUpdateAttribute('last_name', e.target.value)}
              />
            </Form.Item>
          </Col>
        }
        <Col lg={7} xs={24}>
          <p className="label-text mb-10">Cargo</p>
          <Form.Item
            name="profession"
            rules={[{
              required: true,
              message: 'Cargo requerido'
            }]}
          >
            <Input
              id='profession'
              className="cmt-input"
              placeholder="Ingrese cargo"
              onChange={e => onUpdateAttribute('profession', e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col lg={7} xs={24} >
          <Space>
            <p className="label-text mb-10">{t('scheduleTime.patientForm.externalIDTitle')}</p>
            <Popover
              overlayClassName='cmt-popover'
              content={
                <Row>
                  <p className="mb-0">
                    {t('scheduleTime.patientForm.externalIDText')}
                  </p>
                </Row>
              }
              title={
                <Row className="mt-10">
                  <Space>
                    <span className="icon-alert-rounded info-icon" />
                    <h3><strong>{t('scheduleTime.patientForm.externalIDTitle')}</strong></h3>
                  </Space>
                </Row>
              }
              placement="right"
              trigger="hover"
            >
              <span className="icon-alert-rounded info-icon" />
            </Popover>
          </Space>
          <Form.Item
            name="external_id"
          >
            <Input
              id='external_id'
              className="cmt-input"
              placeholder="Ingrese identificador externo"
              onChange={e => onUpdateAttribute('external_id', e.target.value)}
            />
          </Form.Item>
        </Col>
        {
          countryCode === 'PE' &&
          <Col lg={7} xs={24}>
            <p className="label-text mb-10">Correo electrónico</p>
            <Form.Item
              name="email"
              rules={[{
                required: true,
                message: "Correo electrónico requerido"
              }]}
            >
              <Input
                id='email'
                className="cmt-input"
                placeholder="Ingrese correo electrónico"
                onChange={e => onUpdateAttribute('email', e.target.value)}
              />
            </Form.Item>
          </Col>
        }
        <Col lg={7} xs={24} />
      </Row>
      {
        countryCode === 'PE' &&
        <Row gutter={[0, 10]}>
          <Col lg={24} xs={24}>
            <p className="label-text mb-10">Tipo de evaluación</p>
            <Form.Item
              name="evaluation_type"
              className='mb-0'
              rules={[{
                required: true,
                message: 'Tipo de evaluación requerido'
              }]}
            >
              <Radio.Group
                id='evaluation_type'
                onChange={e => onUpdateAttribute('evaluation_type', e.target.value)}
                className='cmt-radio'
              >
                {
                  evaluationTypes.map((type) =>
                    <Radio value={type.value}>{type.text}</Radio>
                  )
                }
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      }
      <ModalExams
        branchOffice={branchOffice}
        open={modalVisible}
        handleCloseModal={() => setModalVisible(false)}
        selectedPatient={patient}
        setCart={setCart}
        countryCode={countryCode}
      />
    </Form>
  )
}

export default PatientForm;