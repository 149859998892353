import {
  Button,
  Checkbox,
  Popover,
  message,
} from "antd";
import { usePermify } from "@permify/react-role";
import React, { useState } from 'react';
import config from "../../../../config";
import {
  downloadReport,
  reservationService
} from '../../../../services/reservations';

const DownloadAttachment = ({ service, selectedExams, handleSelectExam, reservation, country_code }) => {
  const { isAuthorized, isLoading } = usePermify();
  const [loading, setLoading] = useState(false);
  const filterReport = service?.medical_reports?.find(medical_report => medical_report?.name?.toUpperCase().includes('RRHH'));
  const downloadMedicalReport = async (examId) => {
    setLoading(true);
    try {
      const pdf = await downloadReport({
        examId,
        number: reservation.number,
      });
      const base64 = `data:application/pdf;base64,${pdf}`;

      const downloadLink = document.createElement("a");
      const fileName = `informe-${examId}.pdf`;
      downloadLink.href = base64;
      downloadLink.download = fileName;
      downloadLink.click();
    } catch (error) {
      console.error(error);
      message.error({
        content: "Error en descarga del reporte",
        style: {
          marginTop: "40vh",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadReport = async (id) => {
    console.log(country_code);
    const actions = {
      PE: async () => {
        const doctorPermission = await isAuthorized([], "medical_administration");
        console.log("doctorPermission", doctorPermission);
        if (doctorPermission) {
          downloadMedicalReport(id);
        } else {
          downloadFile(filterReport);
        }
      },
      CL: () => {
        downloadMedicalReport(id);
      },
      default: () => {
        console.warn("Country not supported for report download:", country_code);
      },
    };

    const action = actions[country_code] || actions.default;
    await action();
  }

  const downloadFile = async (exam) => {
    setLoading(true);
    try {
      const id = exam.id || exam.trx;
      const type = exam.trx ? 'AdmissionProcedureResult' : 'MedicalReport';
      const response = await reservationService
        .downloadExams({
          id,
          field: 'document',
          type,
        });

      if (response.document_type.includes('application/dicom')) {
        const newWindow = window.open(`${config.dicomURL}?url=${response.document_url}`, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      } else {
        window.open(response.document_url, '_blank');
      }
    } catch (error) {
      console.error(error);
      message.error({
        content: "Error en descarga del reporte",
        style: {
          marginTop: "40vh",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  if (service?.medical_reports?.length === 1 && filterReport) {
    return (
      <>
        {
          filterReport
          && (
            <Button
              loading={loading}
              className="download-report-button"
              onClick={() => downloadFile(filterReport)}
            >
              Descargar informe
            </Button>
          )
        }
      </>
    );
  } else {
    return (
      <>
        {
          (service?.exams?.length || service?.medical_reports?.length)
            ? (
              <>
                <Button
                  loading={loading}
                  className="download-report-button"
                  onClick={() => {
                    handleDownloadReport(service.id);
                  }}
                >
                  Descargar informe
                </Button>
                <Popover
                  trigger="click"
                  content={
                    <div>
                      {
                        [
                          ...(service.exams || []),
                          ...(service.medical_reports || []),
                        ].map((item) => {
                          return (
                            <Button
                              key={item.name}
                              loading={loading}
                              type="link"
                              onClick={() => downloadFile(item)}
                              className="pl-0"
                            >
                              <span className="icon-system-download mr-10" />
                              {item.procedure || item.name}
                            </Button>
                          );
                        })}
                    </div>
                  }
                  title="Adicionales"
                  overlayClassName="download-additional-popover"
                >
                  <span className="icon-clip" />
                </Popover>
                <Checkbox
                  className="desktop-checkbox ml-15"
                  checked={
                    selectedExams?.includes(service.consultationId)
                  }
                  onClick={(event) =>
                    handleSelectExam(
                      event.target.checked,
                      service.consultationId
                    )
                  }
                />
              </>
            ) : (
              <div className="no-clip-wrapper">
                {
                  country_code === 'CL' || (country_code === 'PE' && service?.medical_reports === null)
                    ? <React.Fragment>
                      <Button
                        loading={loading}
                        className="download-report-button"
                        onClick={() => downloadMedicalReport(service.id)}
                      >
                        Descargar informe
                      </Button>
                      <Checkbox
                        className="desktop-checkbox ml-15"
                        checked={
                          selectedExams?.includes(service.consultationId)
                        }
                        onClick={(event) =>
                          handleSelectExam(
                            event.target.checked,
                            service.consultationId
                          )
                        }
                      />
                    </React.Fragment>
                    : <span style={{ display: "inline-block" }} className="ml-20" />
                }
              </div>
            )}
      </>
    );
  }
};

export default DownloadAttachment;