import { Collapse, Row, Col } from "antd";

const { Panel } = Collapse;

const VigilanceCollapse = () => {
  const dataCollapse = [
    {
      title: 'Empresas que realizan actividades de bajo riesgo y cuentan con hasta 20 trabajadores',
      content: 'Requieren la consultoría de un profesional de la salud con capacitación en salud ocupacional, seguridad y salud en el trabajo o gestión de riesgos de desastres.',
    },
    {
      title: 'Empresas que realizan actividades de riesgo y cuentan con hasta 20 trabajadores',
      content: 'Deben solicitar los servicios de un profesional de la salud con capacitación otorgada en salud ocupacional o seguridad y salud en el trabajo (enfermera o médico ocupacional).',
    },
    {
      title: 'Empresas que cuentan entre 21 a 50 trabajadores',
      content: 'Requerirán de los servicios de un profesional de la salud con capacitación en salud ocupacional o seguridad y salud en el trabajo. Adicionalmente, debe cumplir un mínimo de 12 horas semanales.',
    },
    {
      title: 'Empresas que cuentan entre 51 a 100 trabajadores',
      content: 'Requerirán como mínimo de los servicios de una enfermera con entrenamiento en salud ocupacional. Adicionalmente, debe cumplir un mínimo de 18 horas semanales.',
    },
    {
      title: 'Empresas que cuentan entre 101 a 500 trabajadores',
      content: 'Requerirán de los servicios de un médico ocupacional y una enfermera con entrenamiento en salud ocupacional. Adicionalmente, cada profesional debe cumplir un mínimo de 18 horas semanales o 150 horas mensuales.',
    },
    {
      title: 'Empresas que cuentan con más de 500 trabajadores',
      content: 'Requerirán de los servicios de un médico ocupacional y una enfermera con entrenamiento en salud ocupacional. El servicio del profesional de enfermería debe ser de un mínimo de 18 horas semanales, mientras que en el caso del médico ocupacional se deben cumplir 36 horas a la semana.',
    },
  ];

  return (
    <Row justify='center' className="vigilance-wrapper mt-60">
      <Col xxl={19} xl={20} lg={20} xs={22}>
        <h4 className="text-center">Nuestro servicio está orientado a:</h4>
      </Col>
      <Col xl={19} lg={20} xs={22}>
        <Collapse expandIconPosition='end' bordered={false}>
        {
          dataCollapse.map((data, index) => {
            return (
              <Panel key={index} header={<h5 className="mb-0">{data.title}</h5>}>
                <p className="mb-0">{data.content}</p>
              </Panel>
            )
          })
        }
        </Collapse>
      </Col>
    </Row>
  );
};

export default VigilanceCollapse;