import { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { Row, Col, Layout, Collapse, Popconfirm, Button } from "antd"
import PublicLayout from "../components/PublicLayout"
import GoogleMapReact from "google-map-react"
import SEO from "../components/seo"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { handlers } from "../helpers/handlers"

const { Content } = Layout
const { Panel } = Collapse

const BranchOffices = (props) => {
  const {
    data: {
      wpgraphql: {
        branchoffices,
        settings,
      },
    },
  } = props;
  const { t } = useTranslation();
  const countryCode = process.env.GATSBY_COUNTRY_VALUE;
  const [currentBranchOffice, setCurrentBranchOffice] = useState();
  const [defaultCenter, setDefaultCenter] = useState();
  const zoom = 17;


  const onChangeMap = event => {
    if (branchoffices.nodes[parseInt(event)]) {
      setCurrentBranchOffice(branchoffices.nodes[parseInt(event)])
      setDefaultCenter({
        lat: parseFloat(branchoffices.nodes[parseInt(event)]['fields']['latitude']),
        lng: parseFloat(branchoffices.nodes[parseInt(event)]['fields']['length']),
      });
    }
  };

  useEffect(() => {
    if (branchoffices) {
      setCurrentBranchOffice(branchoffices.nodes[0])
      setDefaultCenter({
        lat: parseFloat(branchoffices.nodes[0].fields.latitude),
        lng: parseFloat(branchoffices.nodes[0].fields.length),
      })
    }
  }, [])

  const MapComponent = ({ text, address, name, mapUrl }) => {
    return (
      <Popconfirm
        overlayClassName="branch-office-popover"
        open
        title={
          <div className="popover__content">
            <p>Sucursal {name}</p>
            <p>
              Dirección: &nbsp;
              <a href={mapUrl} target="_blank" rel="noreferrer">
                {address}
              </a>
            </p>
          </div>
        }
      >
        <div className="marker-map">
          <span className="icon-system-placeholder" />
          {text}
        </div>
      </Popconfirm>
    );
  };

  
  
  const CollapseContent = () => {
    return (
      <Collapse
        defaultActiveKey={0}
        accordion
        bordered={false}
        expandIconPosition='end'
        onChange={(event) => onChangeMap(event)}
      >
      {
        branchoffices?.nodes?.map((office, index) => {
          return (
            <Panel
              key={index}
              header={
                <Row justify='space-between' className='header-collapse-wrapper'>
                  <Col>
                    <h3 className="mb-0">{office.fields.name}</h3>
                  </Col>
                  {
                    ['Punta Arenas'].includes(office.fields.name) &&
                      <Col className="new-branch-office">
                        <p className="mb-0">
                          Nueva sucursal
                        </p>
                      </Col>
                  }
                </Row>
              }
            >
              <p className="address mb-15">
                {office.fields.address}
                <br />
                {office.fields.instructions && office.fields.instructions}
              </p>
              <a
                href={office.fields.mapUrl}
                target="_blank"
                rel="noreferrer"
                className='map-url'
              >
                Ver mapa
              </a>
            </Panel>
          )
        })
      }
      </Collapse>
    );
  };

  const schemaExtraData = [{
    "@type": "Organization",
    "logo": "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
    "sameAs": [
      "https://www.facebook.com/CentroMedicoDelTrabajador",
      "https://www.linkedin.com/company/centro-medico-del-trabajador"
    ],
  }];


  return (
    <PublicLayout>
      <SEO
        title={t('seo.branchOffices.title')}
        description={t('seo.branchOffices.description')}
        dataLayer={{
          userId: "",
          pageId: "sucursales",
          pageType: "page",
          category: "",
        }}
        schemaExtraData={schemaExtraData}
      />
      <Layout className='branch-office-container'>
        <Content>
          <Row justify='center'>
            <Col lg={24} xs={24}>
              <Row justify='center' className="main-title-wrapper pt-lg-120 pb-lg-120">
                <Col xl={countryCode === 'CL' ? 10 : 24} lg={countryCode === 'CL' ? 14 : 24} xs={20}>
                  <h1 className='text-center'>{t("branchOffices.title")}</h1>
                  <h2 className='text-center mb-20'>{t("branchOffices.mainTitle")}</h2>
                  {
                    settings.nodes[0].fields.centralPhone &&
                    <p className={`phone text-center ${countryCode === 'PE' && 'mt-lg-40 mb-0'}`}>
                      {countryCode === 'CL' && 'Llámanos'}
                      <a href={`tel:${settings.nodes[0].fields.centralPhone}`}>{handlers.splitPhone(settings.nodes[0].fields.centralPhone)}</a>
                    </p>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify='center' className='mt-lg-90 mt-md-50 mt-sm-50 mt-xs-50 mb-lg-60 mb-md-30 mb-sm-30 mb-xs-30'>
            <Col lg={24} xs={22}>
              <Row justify='center' align='middle' className='time-availability-wrapper'>
                <Col lg={16} xs={24}>
                  <Row justify='center' align='middle' gutter={10}>
                    <Col>
                      <span className='icon-clock-time' />
                    </Col>
                    <Col>
                      <h3 className='text-center mb-0'>{t('branchOffices.scheduleTitle')}</h3>
                    </Col>
                  </Row>
                </Col>
                {
                  countryCode === 'CL' &&
                    <Col lg={16} xs={24} className="mt-10 mb-20">
                      <Row justify="center" align="middle">
                        <Col className="new-schedule">
                          <p className="mb-0">
                            Nuevo Horario
                          </p>
                        </Col>
                      </Row>
                    </Col>
                }
                <Col lg={16} xs={22} className={countryCode === 'PE' && 'mt-30'}>
                  <Row justify='center' align='middle'>
                    <Col>
                      <h4 className='mb-5 text-center'>{t('branchOffices.scheduleSubTitle')}</h4>
                      <h5 className="text-center" dangerouslySetInnerHTML={{ __html: t('branchOffices.scheduleContent') }} />
                    </Col>
                  </Row>
                </Col>
                <Col lg={16} xs={22}>
                  <Row justify='center' align='middle'>
                    <Col className="mt-15">
                      <h4 className='mb-5 text-center'>{t('branchOffices.scheduleSubTitle2')}</h4>
                      <h5 className="text-center" dangerouslySetInnerHTML={{ __html: t('branchOffices.scheduleContent2')}} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify='center' className='collapse-map-wrapper mb-lg-130 mb-md-40 mb-sm-40 mb-xs-40'>
            <Col xxl={15} lg={20} xs={22}>
              <Row justify='space-between'>
                <Col lg={9} xs={24}>
                  {CollapseContent()}
                </Col>
                <Col lg={12} className='desktop-map-container'>
                {
                  currentBranchOffice && (
                    <div className="map-content">
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: "AIzaSyCKP-G1YcisnbzHPVGBh6MWF8t9nliNcc8",
                        }}
                        center={defaultCenter}
                        defaultZoom={zoom}
                      >
                        <MapComponent
                          name={currentBranchOffice.fields.name}
                          lat={parseFloat(
                            currentBranchOffice.fields.latitude
                          )}
                          lng={parseFloat(
                            currentBranchOffice.fields.length
                          )}
                          address={currentBranchOffice.fields.address}
                          mapUrl={currentBranchOffice.fields.mapUrl}
                        />
                      </GoogleMapReact>
                    </div>
                  )
                }
                <Row justify='end'>
                  <Col className='mt-30'>
                    <Link to='/app/reservar/'>
                      <Button
                        className='cmt-button secondary'
                        type='primary'
                        size={countryCode === 'CL' && 'large'}
                      >
                        Agendar aquí
                      </Button>
                    </Link>
                  </Col>
                </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    </PublicLayout>
  );
};

export default BranchOffices;

export const postQuery = graphql`
  query GET_BRANCH_OFFICES($country: String, $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    wpgraphql {
      branchoffices(where: { orderby: { field: MENU_ORDER, order: ASC }, country: $country }, first: 20) {
        nodes {
          id
          fields {
            name
            address
            instructions
            latitude
            length
            mapUrl
          }
        }
      }
      settings(where: { country: $country }) {
        nodes {
          fields {
            centralPhone
          }
        }
      }
    }
  }
`