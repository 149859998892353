import { Link } from "gatsby";
import { Row, Col } from "antd";

const OurHighlights = ({ t, countryCode }) => {

  const cardsContent = [
    {
      icon: 'icon-health-hexagon',
      iconTitle: 'Nuestros exámenes',
      title: 'Nuestros exámenes',
      subTitle: 'Revisa nuestros servicios',
      link: '/personaliza-tu-protocolo/'
    },
    {
      icon: 'icon-immediate-agenda',
      iconTitle: 'Agenda Inmediata',
      title: 'Agenda inmediata',
      subTitle: 'Encuentra tu hora en el mismo día',
      link: '/app/reservar/'
    },
    {
      icon: 'icon-cyclical-worker',
      iconTitle: 'Adaptabilidad',
      title: 'Ágiles y flexibles',
      subTitle: 'Nos adaptamos a tus necesidades',
      link: '/nosotros/'
    },
    {
      icon: 'icon-map-placeholder',
      iconTitle: 'Cobertura a nivel nacional',
      title: 'Cobertura nacional',
      subTitle: '15 sucursales desde Iquique a Punta Arenas',
      link: '/sucursales/'
    },
  ];


  return (
    <Row justify='center' className='our-highlights-container mb-130'>
      <Col lg={19} md={18} xs={22}>
        <Row justify="center">
          <Col
            lg={24} md={8} sm={11} xs={24}
            dangerouslySetInnerHTML={{ __html: t('home.ourHighlights.main')}}
          />
          {
            countryCode === 'PE' &&
              <Col xl={22} lg={23} md={24} sm={24} xs={23}>
                <p className="sub-title text-center mb-15">{t('home.ourHighlights.subTitle')}</p>
                <p className="text-content text-center mb-70">{t('home.ourHighlights.textContent')}</p>
              </Col>
          }
        </Row>
        <Row justify='center' gutter={[60, 20]}>
        {
          cardsContent.map((item, index) => {
            if (countryCode === 'PE' && ([3, 4].includes(index))) {
              return null;
            }

            if (countryCode === 'CL' && (index === 0 || index === 4)) {
              return null;
            }

            return (
              <Col
                xxl={5}
                lg={6}
                xs={18}
                key={index}
                className='card-container'
              >
                <Link to={item.link}>
                  <Row className='description'>
                    <Col lg={24} xs={24} className='pr-30 pl-30 pt-50'>
                      <Row justify='center' className='mb-15'>
                        <span className={item.icon} title={`Icono ${item.iconTitle}`} />
                      </Row>
                    </Col>
                    <Col lg={24} xs={24} className='pr-30 pl-30'>
                      <h3 className='text-center mb-5'>{item.title}</h3>
                    </Col>
                    <Col lg={24} xs={24} className='pr-30 pl-30 pb-20'>
                      <p className='text-center'>{item.subTitle}</p>
                    </Col>
                    <Col lg={24} xs={24} className='pr-30 pl-30'>
                      <p className="see-more text-center mb-20">Ver más</p>
                    </Col>
                    <Col lg={24} xs={24} className='orange-bar' />
                  </Row>
                </Link>
              </Col>
            )
          })
        }
        </Row>
      </Col>
    </Row>
  );
};

export default OurHighlights;