import React from 'react';
import { Row, Col, Layout } from 'antd';
import PublicLayout from '../components/PublicLayout';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import { useTranslation } from 'react-i18next';
import ProtocolCards from './components/customProtocol/protocolCards';

const { Content } = Layout;

const CustomProtocol = ({ pageContext }) => {
  const {
    type,
    exams,
    otherBatteries,
    countryCode,
  } = pageContext;
  const { t } = useTranslation();

  const schemaExtraData = [{
    "@type": "Product",
    "brand": {
      "@type": "Brand",
      "name": "CMT Salud",
    }
  }];


  return (
    <PublicLayout>
      <SEO
        title={type === 'form' ? t('seo.customProtocol.title') : t('seo.customProtocol.successTitle') }
        description={type === 'form' ? t('seo.customProtocol.description') : t('seo.customProtocol.successDescription')}
        dataLayer={{
          userId: "",
          pageId: 'custom-protocol',
          pageType: "customProtocol",
          category: "",
        }}
        schemaExtraData={schemaExtraData}
      />
      <Layout>
        <Content>
          <Row justify='center'>
            <Col xl={19} lg={20} xs={24} className='mt-lg-100 mt-md-60 mt-sm-60 mt-xs-60 mb-lg-160 mb-md-50 mb-sm-50 mb-xs-50'>
              <ProtocolCards
                type={type}
                from='pageTemplate'
                exams={exams}
                otherBatteries={otherBatteries}
                countryCode={countryCode}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
    </PublicLayout>
  );
};

export default CustomProtocol;

export const postQuery = graphql`
  query GET_LOCALE($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;