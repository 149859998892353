import React from "react"
import { Link } from "gatsby"
import { Col, Row, Card, Tag } from 'antd'
import Pagination from "../../../components/pagination"
import imageUrlReplace from './imageUrlReplace'
import moment from 'moment'

const { Meta } = Card

const Posts = ({ posts, pageNumber, hasNextPage }) => {

  const getImage = (post) => {
    const backgroundImage = {
      height: 205,
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    };

    if (post.featuredImage) {
      backgroundImage['backgroundImage'] = `url('${imageUrlReplace(post.featuredImage.node.sourceUrl)}')`;
    } else {
      backgroundImage['backgroundImage'] = `url('https://via.placeholder.com/500?text=SinImagen')`;
      backgroundImage['backgroundPosition'] = 'center';
    }

    return (
      <div
        style={backgroundImage}
        title={post.featuredImage.node.altText ? post.featuredImage.node.altText : post.featuredImage.node.title}
      >
      {
        post.categories.nodes.map((category, index) => {
          return (
            <Link key={index} to={`/blog/${category.slug}/`}>
              <Tag className='category-tag'>
                {category.name}
              </Tag>
            </Link>
          )
        })
      }
      </div>
    );
  };

  const cardDescription = (date, title, slug) => {
    return (
      <Row>
        <Col lg={24} xs={24}>
          <p className='date-title mb-5'>Publicado el {moment(date, "YYYY-MM-DD").format("LL")}</p>
        </Col>
        <Col lg={24} xs={24}>
          <h3 className='card-title'>{title}</h3>
        </Col>
        <Col lg={24} xs={24}>
          <Link to={`/blog/${slug}/`}>
            Ver Noticia
          </Link>
        </Col>
      </Row>
    );
  };


  return (
    <Row justify='center' className='posts-wrapper pb-80'>
      <Col lg={21} xs={22}>
        <Row justify='center'>
          <Col lg={24} md={16} xs={24}>
            <Row gutter={[40, 40]}>
            {
              posts.nodes.map(post => {
                return (
                  <Col
                    key={post.id}
                    lg={8} xs={24}
                    className="card-wrapper"
                  >
                    <Card cover={getImage(post)}>
                      <Meta
                        description={cardDescription(post.date, post.title, post.slug)}
                      />
                    </Card>
                  </Col>
                )
              })
            }
            </Row>
          </Col>
          <Col lg={24} xs={24}>
            <Pagination pageNumber={pageNumber} hasNextPage={hasNextPage} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Posts;