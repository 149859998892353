import React from "react"
import { useLocation } from '@reach/router'
import { navigate } from "gatsby"
import { Row, Col, Tag } from 'antd'

const { CheckableTag } = Tag

const Categories = ({ categories }) => {
  const location = useLocation()

  const handleChange = (category, checked) => {
    if (!checked) {
      navigate(`/blog/`)
    } else {
      navigate(`/blog/${category.slug}/`)
    }
  }

  return (
    <Row justify='center' className="mt-lg-80 mt-md-50 mt-sm-50 mt-xs-50 mb-30">
      <Col lg={24} xs={22}>
        <Row justify='center' gutter={[0, 15]} className="category-wrapper">
        {
          categories.map(category => {
            return (
              <CheckableTag
                key={category.id}
                checked={location && location.pathname === `/blog/${category.slug}/`}
                onChange={checked => handleChange(category, checked)}
                className="category-tag mr-15"
              >
                {category.name}
              </CheckableTag> 
            )
          })
        }
        </Row>
      </Col>
    </Row>
  );
};

export default Categories;