import React from "react"
import { Col, Row } from 'antd'

const Header = ({ category, t }) => {
  return (
    <Row justify='center' className="header-wrapper">
      <Col lg={21} xs={21}>
        <Row justify='center'>
          <Col lg={24} xs={24}>
            <h1
              className='text-center'
              dangerouslySetInnerHTML={{ __html: `${category ? `${category}:` : ''} ${t("blog.title")}`}}
            />
          </Col>
          <br />
          <Col lg={20} xs={24}>
            <p
              className="text-center mb-0"
              dangerouslySetInnerHTML={{ __html: t("blog.description")}}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Header;
