import React from 'react';
import { Row, Col, Button } from 'antd';
import { Link } from 'gatsby';
import { sortExamsByName } from '../../../helpers/sortByExamName';

const ExamsCards = ({ t, usedFrom, allExams, countryCode }) => {

  const cardItems = (allExams) => {
    let _allExams = allExams.exams;

    if (allExams.otherBatteries.length > 0) {
      _allExams = [..._allExams, {
        title: allExams.otherBatteries[0].fields.title,
        slug: allExams.otherBatteries[0].slug,
        fields: {
          icon: allExams.otherBatteries[0].fields.icon,
        },
      }];
    }

    _allExams = sortExamsByName(_allExams, countryCode, usedFrom);

    return (
      <Row
        justify='center'
        gutter={30}
        className='exam-card-wrapper mt-lg-20 mt-md-20 mt-sm-20 mt-xs-20'
      >
      {
        _allExams.map((item, index) => {
          return (
            <Col lg={6} xs={24} key={index} className='mb-lg-50 mb-md-20 mb-sm-20 mb-xs-20'>
              <Link
                to={`/examenes/${item.slug}/`}
                className='link-item-container'
              >
                <div className='card-wrapper'>
                  <Row justify='center' className='card-item pr-lg-15 pl-lg-15' align='middle'>
                    <Col lg={22} md={2} sm={4} xs={6}>
                      <Row justify='center' align='middle'>
                        <Col className='icon-wrapper'>
                          <span className={item.fields.icon} title={item.title} />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={24} md={20} sm={18} xs={16} className='mt-lg-30'>
                      <p className='mb-0'>{item.title}</p>
                    </Col>
                    <Col className='see-more-wrapper mt-30'>
                      <Link to={`/examenes/${item.slug}/`}>
                        Ver más
                      </Link>
                    </Col>
                    <Col xs={2} className='chevron-mobile-wrapper'>
                      <span className='icon-system-chevron-right' />
                    </Col>
                  </Row>
                  <Row className='orange-bar-desktop' />
                </div>
              </Link>
            </Col>
          )
        })
      }
      </Row>
    );
  };

  const binationalContent = () => (
    <Row justify='center' className='binational-content mt-30'>
      <Col xl={4} lg={5} xs={24}>
        <Link to='/contacto/'>
          <Button className='cmt-button secondary' type='primary' size='large'>
            Contáctanos
          </Button>
        </Link>
      </Col>
      <Col lg={24} xs={24} className='mt-lg-80 mt-md-50 mt-sm-50 mt-xs-50'>
        <Row justify='space-between' gutter={[0, 30]}>
          <Col lg={11} xs={24} className='card-item'>
            <h5>Optimización</h5>
            <ul>
              <li>Hacemos más eficiente la gestión de candidatos que van y vienen entre Chile y Perú.</li>
            </ul>
          </Col>
          <Col lg={11} xs={24} className='card-item'>
            <h5 className='mb-10'>Eficiencia Operativa</h5>
            <ul>
              <li>Reducción de los tiempos de contratación.</li>
              <li>Menores costos administrativos en la gestión del candidato.</li>
            </ul>
          </Col>
        </Row>
      </Col>
      <Col lg={24} xs={24} className='mt-lg-130 mt-md-60 mt-sm-60 mt-xs-60'>
        <p className='text-center'>Te invitamos a revisar nuestro amplio listado <br/> de&nbsp;<italic>exámenes binacionales</italic>&nbsp;disponibles</p>
      </Col>
    </Row>
  );


  return (
    <Row justify='center' id="examsCards" className='pt-lg-60 pt-md-10 pt-sm-10 pt-xs-10 pb-40'>
      <Col lg={24} xs={24}>
      {
        usedFrom === 'preocupational' ?
          <h1 className='text-center'>{t('preocupationalServices.mainTitle')}</h1>
        :
          <h4 className='text-center'>{t('preocupationalServices.mainTitle')}</h4>
      }
      <h5 className='text-center' dangerouslySetInnerHTML={{ __html: t('preocupationalServices.subTitle')}} />
      </Col>
      {
        countryCode === 'PE' &&
        <Col lg={24} xs={24}>
          {binationalContent()}
        </Col>
      }
      <Col lg={24} xs={24}>
      {
        allExams && cardItems(allExams)
      }
      </Col>      
    </Row>
  );
};

export default ExamsCards;
